import { TrashIcon, XIcon } from "@heroicons/react/outline";
import { data } from "autoprefixer";
import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const Cart = (props) => {
    useEffect(()=>{
        props.getCartData()
    },[])

    



    const increaseCart = (id) => {
        axios.patch('http://15.165.5.173:3000/api/increaseCart', {
            phone: props.user,
            id: id
          })
          .then(function (response) {
            props.getCartData()
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    const decreaseCart = (id) => {
        axios.patch('http://15.165.5.173:3000/api/decreaseCart', {
            phone: props.user,
            id: id
          })
          .then(function (response) {
            props.getCartData()
          })
          .catch(function (error) {
            console.log(error);
          });
          
    }

    const deleteCart = (id)=> {
        axios.patch('http://15.165.5.173:3000/api/deleteCart', {
            phone: props.user,
            id: id
          })
          .then(function (response) {
            props.getCartData()
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    


    return (
    <>   
      <motion.div
        className="w-full h-screen  fixed top-0 left-0 flex justify-end"
        initial={{ y: "-100%" }}
        animate={{ y: 0 }}
        exit={{ y: "-100%" }}
        transition={{ duration: 0.3 }}
      >
            <div className="w-full h-screen  fixed top-0 left-0 flex justify-end  ">
                    <div className="bg-[#00000050]  2xl:w-full xl:w-full lg-w-full md:w-full w-0" onClick={()=> props.handleCart()}>
                    </div>
                    <div className="mt-[80px] relative bg-slate-50  2xl:w-[30%] lg:w-[40%] md:w-[70%] w-full h-full overflow-y-auto shadow-sm">
                        <div className="w-full h-16 mt-0 shadow-md flex px-4 justify-between rounded-sm bg-white">
                            <button><p className="text-2xl font-bold ">Shopping Cart</p></button>
                            <button className="text-zinc-800 hover:text-red-600" onClick={()=> props.handleCart()}><XIcon className="stroke-1 w-9 h-9"/></button>
                        </div>
                        <div className="px-3">
                            <ul className="mt-[10px] space-y-3">
                                {props.cart && props.cart.map((item,i)=>(
                                <li key={i}>
                                    <div className="px-4 h-32 py-2 rounded-lg border bg-white  shadow-lg justify-center ">
                                        <div className="flex pt-2 h-24  justify-between">
                                            <img src={item.images[0]} width="85px" height="85px"  className="rounded-lg object-cover"  alt="" />
                                            <div className="w-full pb-2 text-start grid grid-cols-1">
                                                <p className="ml-4 pt-1 font-semibold">{item.product_name}</p>
                                                <div className="w-full flex justify-between">
                                                <div className="ml-4 mt-4    justify-between rounded-lg h-8 flex">
                                                    <button className="text-white rounded-l-lg bg-black w-12 hover:bg-gray-800" onClick={()=> decreaseCart(item.product_id)}>-</button>
                                                    <button className="text-white w-full bg-black">{item.quantity}</button>
                                                    <button className="text-white rounded-r-lg bg-black w-12 hover:bg-gray-800" onClick={()=> increaseCart(item.product_id)}>+</button>
                                                </div>
                                                <button><p className="ml-4 w-full font-semibold mt-4">{Number(item.total).toLocaleString('ko-KR')}<span className="text-[14px]">₩</span></p></button>
                                                </div>
                                            </div>
                                            <TrashIcon className="stroke-1 w-8 h-8 text-zinc-800 hover:text-red-600 cursor-pointer" onClick={()=> {deleteCart(item.product_id)}}/>
                                        </div>            
                                    </div>
                                </li>
                                ))}
                                {props.cart.length !== 0 ? 
                                <li>   
                                <div className="h-56">
                                    <div className="px-3 mt-8"> 
                                        <Link to="/checkout">
                                            <div className="h-20 relative w-full flex justify-center rounded-xl  bg-black hover:bg-zinc-800 ">
                                            <button className="text-white font-semibold w-full">Proceed to Checkout</button>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </li>: ""
                                }
                                
                            </ul>
                        </div>
                    </div>
                    
            </div>
            </motion.div>
    </>
    );
}
export default Cart;